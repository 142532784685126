<template>
    <article class="product-cart" v-if="product">
        <div class="product-cart__images-ibg">
            <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
                <img v-lazy="getImageProduct(product)" :style="getImageStyle(product)" :alt="product.name" />
            </router-link>
            <div class="product-cart__labels">
                <button class="product-cart__label _icon-favorite" :class="{ active: isInWishlist }" @click="toggleWishlist(product)"></button>
            </div>
            <div class="product-cart__labels2" v-if="product.old_price">{{ Math.round(((oldPrice - price) / oldPrice) * 100) }}%</div>
            <div class="product-cart__labels3" v-if="Array.isArray(product.category) && product.category.some((category) => category.id == 2)">ХІТ</div>
        </div>
        <div class="product-cart__info">
            <h3 class="product-cart__title">
                <router-link :to="{ name: 'Product', params: { slug: product.slug } }">{{ product.name }}</router-link>
            </h3>
            <div class="product-cart__materials" v-if="product._materials">
                <span>
                    {{ product._materials }}
                </span>
            </div>
            <div class="product-cart__materials" v-else-if="product.materials && Object.keys(product.materials).length">
                <span>
                    {{ [].concat(...Object.values(product.materials || {}).map((group) => group.map((item) => item.value))).join(", ") }}
                </span>
            </div>
            <div class="product-cart__materials" v-else>
                <span>
                    {{ (product.material || []).map((item) => item.value).join(", ") }}
                </span>
            </div>
        </div>
        <div class="product-cart__price price">
            <div :class="{ price__current: true, sale: true }">{{ price }} грн.</div>
            <div v-if="product.old_price" class="price__old">{{ oldPrice }} грн.</div>
        </div>
        <div class="product-cart__button">
            <button class="button" @click="addToCart(product)">
                <span>
                    <!-- <img src="../assets/img/pautina.png" alt="" /> -->
                    Додати в кошик
                </span>
            </button>
        </div>
        <transition name="slide-fade">
            <div class="product-cart__variants" v-if="showVariants">
                <button type="button" class="product-cart__close _icon-close" @click="hiddenVariants()"></button>
                <VariantItem v-if="variants && variants.variants" class="sizes_small content-product__sizes" :data="variants.variants" :selectedSize="selectedSize" @selected="selectedSize = $event" />
            </div>
        </transition>
    </article>
</template>

<script setup>
/* global dataLayer */
import { ref, computed, onMounted, getCurrentInstance, defineProps, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import VariantItem from "./VariantItem.vue";
import axios from "axios";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const toast = useToast();
const router = useRouter();
const store = useStore();
const showVariants = ref(false);
const isInWishlist = ref(false);
const selectedSize = ref(null);
const component = getCurrentInstance(); // Получение доступа к экземпляру компонента

const totalPrice = computed(() => store.getters.totalPriceCart);

const instance = getCurrentInstance();
const getCookie = instance.appContext.config.globalProperties.$getCookie;
const getEventId = instance.appContext.config.globalProperties.$getEventId;
const event_id = getEventId();
const external_id = localStorage.getItem("external_id");
const apiUrl = process.env.VUE_APP_PROD_API_URL;
const variants = ref([]);
const selectedVariant = ref(null);
const uploadUrl = ref(component.appContext.config.globalProperties.$uploadUrl);
const emit = defineEmits(["updateShowVariants"]);

const price = computed(() => {
    if (props.product.is_set === 1 && props.product.sets.length > 0) {
        const setsPorducCount = props.product.sets.length;
        let discount = 0;
        if (setsPorducCount === 2) {
            discount = 0.08;
        } else if (setsPorducCount >= 3) {
            discount = 0.15;
        }
        return Object.values(props.product.sets).reduce((total, set) => total + Math.round(set.price_origin * (1 - discount)), 0);
    } else {
        return Math.round(props.product.price);
    }
});

const oldPrice = computed(() => {
    if (props.product.is_set === 1 && props.product.sets.length > 0) {
        return Object.values(props.product.sets).reduce((total, set) => total + Math.round(set.old_price), 0);
    } else {
        return Math.round(props.product.old_price);
    }
});

function getImageProduct(product) {
    const downloadWithPositionOne = product.downloads.find((d) => d.position === 1);
    const firstDownload = product.downloads[0];
    if (downloadWithPositionOne?.path) {
        return "https://ig.le-perle.com.ua/" + downloadWithPositionOne.path;
        // return "https://ig.le-perle.com.ua/rs/?width=640&height=0&path=" + downloadWithPositionOne.path;
    } else if (firstDownload?.path) {
        return "https://ig.le-perle.com.ua/" + firstDownload.path;
        // return "https://ig.le-perle.com.ua/rs/?width=640&height=0&path=" + firstDownload.path;
    } else {
        return "https://api.le-perle.com.ua/assets/img/logo.svg";
    }
}

function getImageStyle(product) {
    const downloadWithPositionOne = product.downloads.find((d) => d.position === 1);
    if (downloadWithPositionOne && downloadWithPositionOne.provisions !== null) {
        return { "object-position": downloadWithPositionOne.provisions };
    }
    return {};
}

function toggleWishlist(product) {
    let wishlist = localStorage.getItem("wishlist");
    wishlist = wishlist ? JSON.parse(wishlist) : [];
    if (wishlist.some((wishItem) => wishItem.id === product.id)) {
        wishlist = wishlist.filter((wishItem) => wishItem.id !== product.id);
        isInWishlist.value = false;
        toast.error(product.name + " <b>видалено з обраного!</b>");
    } else {
        wishlist.push(product);
        isInWishlist.value = true;
        toast.success(product.name + " <b>додано до обраного!</b>");
    }
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
}

function addToCart(product) {
    const hasVariants = product.variants && product.variants.length > 0;
    const hasSets = product.is_set == 1;

    if (hasSets) {
        router.push({ name: "Product", params: { slug: product.slug } });
    } else if (hasVariants) {
        getVariants();
        showVariants.value = true;
    } else {
        let cart = localStorage.getItem("cart");
        cart = cart ? JSON.parse(cart) : [];
        const itemToAdd = {
            id: product.id,
            version_update: 1,
            product_id: product.id,
            is_set: 0,
            variant_id: null,
            vendor_code: product.vendor_code,
            price: product.price,
            percent: product.percent,
            quantity: 1,
            product: product,
            variant: [],
        };
        const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
        if (existingProductIndex >= 0) {
            cart[existingProductIndex].quantity++;
        } else {
            cart.push(itemToAdd);
        }
        store.dispatch("updateCart", cart);
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                value: totalPrice.value,
                currency: "UAH",
                items: [
                    {
                        item_name: product.name,
                        item_id: product.vendor_code,
                        price: parseFloat(product?.price),
                        item_brand: "Le-perle",
                        item_category: product.category?.[0]?.title || "",
                        item_variant: product.vendor_code,
                        quantity: 1,
                    },
                ],
            },
        });
        window.fbq("track", "AddToCart", {
            content_ids: product.vendor_code,
            content_type: "product",
            external_id: external_id,
            event_id: event_id,
            content_name: product.name,
            currency: "UAH",
            source: "le-perle.com.ua",
            value: parseFloat(product.price),
        });
        try {
            axios.post(apiUrl + "fbapi", {
                action: "AddToCart",
                event_id: event_id,
                value: parseFloat(product.price),
                content_name: product.name,
                content_ids: product.vendor_code,
                quantity: 1,
                fbc: getCookie("_fbc"),
                fbp: getCookie("_fbp"),
                external_id: external_id,
                useragent: window.navigator.userAgent,
                url: `${window.location.origin}${window.location.pathname}`,
            });
        } catch (error) {
            console.error(error);
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        document.dispatchEvent(new CustomEvent("cart-updated"));
        store.commit("openCart");
    }
}

function addToCartVariant() {
    const variant = selectedVariant.value;
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const product = ref(props.product);

    const engraving = "";
    const baseItem = {
        product_id: product.value.id,
        version_update: 1,
        slug: product.value.slug,
        variant_id: variant ? variant.id : null,
        vendor_code: variant ? variant.vendor_code : product.value.vendor_code,
        quantity: 1,
        category: product.value.category ? product.value.category.title : "",
        media: product.value.downloads[0]?.path ? `${uploadUrl.value}${product.value.downloads[0].path}` : "https://api.le-perle.com.ua/assets/img/logo.svg",
        price: variant ? variant.price : product.value.price,
        material: product.value.material,
        percent: product.value.percent ?? 0,
        product: {
            id: product.value.id,
            name: product.value.name,
            vendor_code: product.value.vendor_code,
            price: product.value.price,
            old_price: product.value.old_price,
            category: product.value.category ? product.value.category.title : "",
            is_set: product.value.is_set,
            in_stock: product.value.in_stock,
        },
        ...(engraving && { engraving }),
    };

    const itemToAdd = { ...baseItem, id: variant ? variant.id : product.value.id, is_set: 0, parent_id: product.value.id, sets: {}, variant: variant || {} };

    const existingItemIndex = cart.findIndex((item) => item.id === itemToAdd.id);

    if (existingItemIndex !== -1) {
        cart[existingItemIndex].quantity += itemToAdd.quantity;
    } else {
        cart.push(itemToAdd);
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    document.dispatchEvent(new CustomEvent("cart-updated"));
    // store.commit("openCart");

    toast.success("<b>" + product.value.name + "</b> додано до корзини!", {
        position: "top-right",
    });

    store.dispatch("updateCart", cart);
}

function getVariants() {
    axios
        .get(apiUrl + "products/" + props.product.slug + "/variants")
        .then((response) => {
            variants.value = response.data;
        })
        .catch((error) => {
            console.error("Error fetching variants:", error);
        });
}

function hiddenVariants() {
    showVariants.value = false;
    emit("updateShowVariants", showVariants.value);
}

onMounted(() => {
    let wishlist = localStorage.getItem("wishlist");
    wishlist = wishlist ? JSON.parse(wishlist) : [];
    isInWishlist.value = wishlist.some((wishItem) => wishItem.id === props.product.id);
});

watch(selectedSize, (newSize) => {
    const variant = variants.value.data.find((variant) => variant.vendor_code === newSize.vendor_code);
    if (variant) {
        selectedVariant.value = variant;
    }
    addToCartVariant();
    hiddenVariants();
    emit("countShowVariants", showVariants.value);
});

watch(showVariants, (newValue) => {
    emit("updateShowVariants", newValue);
});
</script>

<style lang="scss">
.product-cart {
    position: relative;
    &__variants {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        @media (max-width: $mobile) {
            padding: 10px;
        }
    }

    &__close {
        position: absolute;
        right: 20px;
    }
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>
